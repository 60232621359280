<template>
  <div>
    <div class="indexUser">
      <div>
        <!-- header  点击 -->
        <headers :userList="userList"></headers>
      </div>
      <div class="bg1 userBox">
        <div class="wrap bgw">
          <div class="nav">功能反馈</div>
          <div class="content">
            <div>
              <div class="fl">反馈描述</div>
              <div class="fl">
                <textarea v-model="content" maxlength="1000" placeholder="请详细描述您的意见，方便我们改进。"></textarea>
              </div>
            </div>
            <div>
              <button @click="send()">提交</button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <publicBottom></publicBottom>
      </div>
    </div>
  </div>
</template>
<script>
import headers from "./header"; // 头部 TOP 和header
import publicBottom from "@/components/public/public_bottom"; //底部
import { usergetinfo } from "@/request/user";
import { feedback } from "@/request/user";
export default {
  components: {
    headers,
    publicBottom
  },
  data() {
    return {
      userList: [], // 个人信息
      content: " "
    };
  },
  created() {
    let datas = localStorage.getItem("lzyyToken");
    let Athis = this;
    if (datas != null) {
      this.$tokens = datas;
    } else {
      this.$message.error("登录错误，请重新登录");
      setTimeout(function() {
        Athis.$router.replace({ path: "/land" });
      }, 1000);
    }

    this.init();
    // this.state=this.$route.query.state
  },
  methods: {
    send() {
      if (this.content == " ") {
        this.$message.error("请填写 描述");
      } else {
        feedback({
          token: this.$tokens,
          content: this.content
        }).then(res => {
          if (res.code == 1) {
            this.$message({
              message: "提交成功",
              type: "success"
            });
          }
          this.$router.push('/user/index')
        });
      }
    },
    init() {
      //   个人信息
      usergetinfo({
        token: this.$tokens
      }).then(res => {
        this.userList = res.data;
      });
    }
  }
};
</script>

<style lang="less" scoped>
.nav {
  height: 68px;
  line-height: 68px;
  border-bottom: 1px solid #e6e6e6;
  font-size: 18px;
  padding-left: 20px;
  display: flex;
}
.content {
  height: 600px;
  padding-left: 70px;
  padding-top: 32px;
  div {
    overflow: hidden;
    margin-bottom: 40px;
  }
  .fl:nth-of-type(1) {
    width: 150px;
  }
  textarea {
    width: 490px;
    height: 244px;
    text-indent: 10px;
    font-size: 16px;
    padding-top: 10px;
    resize: none;
  }
  button {
    width: 130px;
    height: 46px;
    font-size: 18px;
  }
}
</style>